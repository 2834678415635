import * as React from "react"
import Fade from "react-reveal/Fade"
import { Element } from "react-scroll"
import { Link } from "gatsby"
import ArticlesItem from "./articlesItem"

const Articles = ({ nodes, isHomePage, name, slug }) => {
  if (!nodes.length > 0) return false

  return (
    <Element name={slug} id={slug}>
      <section
        className={`articles-layout ${isHomePage ? "section-spacing" : ""}`}
      >
        <div className="articles-layout__aside">
          <Fade ssrReveal delay={225} duration={500}>
            <h3 className="heading-alpha mb-4 lg:mb-8 xl:mb-12">
              Latest {name}
            </h3>
            <Link className="btn-primary btn-primary--blue" to="/sign-up">
              sign up for news updates
            </Link>
          </Fade>
        </div>
        <div className="articles-layout__main">
          {nodes.map((item, index) => {
            return (
              <ArticlesItem key={index} item={item} index={index} slug={slug} />
            )
          })}
          {isHomePage && (
            <div className="mt-12">
              <Link className="btn-primary" to={`/${slug}`}>
                More {name}
              </Link>
            </div>
          )}
        </div>
      </section>
    </Element>
  )
}

export default Articles
