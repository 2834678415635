import * as React from "react"
import { Element } from "react-scroll"
import Fade from "react-reveal/Fade"
import ContactForm from "./contactForm"

const nl2br = require("react-nl2br")

const Contact = ({ heading, body }) => {
  return (
    <Fade ssrReveal delay={225} duration={500}>
      <div className="section-spacing-alt overflow-hidden">
        <Element name="demo" id="demo">
          <section className="grid grid-cols-12 gap-x-gs gap-y-5 md:gap-y-10 px-ogs">
            <div className="col-span-12 md:col-span-6">
              {heading && (
                <h3 className="heading-alpha mb-3 md:mb-6 lg:mb-8">
                  {nl2br(heading)}
                </h3>
              )}
              {body.html && (
                <div
                  className="text-beta md:max-w-md space-y-4"
                  dangerouslySetInnerHTML={{
                    __html: body.html,
                  }}
                ></div>
              )}
            </div>
            <div className="col-start-1 col-span-12 md:col-span-6 xl:col-start-7 xl:col-span-5 relative z-10">
              <div
                style={{ maxWidth: 762 + "px", width: 150 + "%" }}
                className="hidden md:block absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 z-10"
              >
                <svg
                  className="w-full text-blue-light fill-current opacity-30 mx-auto"
                  viewBox="0 0 762 762"
                >
                  <path d="M381,762A381.09,381.09,0,0,1,232.69,30a381.09,381.09,0,0,1,296.62,702.1A378.66,378.66,0,0,1,381,762Zm0-732A351,351,0,0,0,132.81,629.19,351,351,0,1,0,629.19,132.81,348.66,348.66,0,0,0,381,30Z" />
                </svg>
              </div>
              <div className="relative bg-blue-light rounded-md px-5 py-6 sm:p-6 md:p-8 lg:pt-10 lg:pb-14 lg:px-12.5 shadow-contact z-20">
                <ContactForm />
              </div>
            </div>
          </section>
        </Element>
      </div>
    </Fade>
  )
}

export default Contact
