exports.linkResolver = doc => {
  if (doc.type === "page") {
    return `/news/${doc.uid}`
  }
  if (doc.type === "resource") {
    return `/resource/${doc.uid}`
  }
  if (doc.type === "document") {
    return `/${doc.uid}`
  }
  return "/"
}
