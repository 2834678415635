import * as React from "react"
import Fade from "react-reveal/Fade"
import { Element } from "react-scroll"
import { StaticImage } from "gatsby-plugin-image"

const nl2br = require("react-nl2br")

const Feature = ({ heading, body }) => {
  if (!heading) return null

  return (
    <Element name="refund-control" id="refund-control">
      <Fade ssrReveal delay={225} duration={500}>
        <section className="grid grid-cols-12 gap-gs px-ogs mb-8 md:mb-14 xl:mb-18">
          <div className="col-start-1 col-span-12 md:col-span-5 xl:col-start-2 xl:col-span-4 md:pt-6">
            {heading && (
              <h3 className="heading-alpha mb-6 md:mb-10 xl:mb-20">
                {nl2br(heading)}
              </h3>
            )}
            {body.html && (
              <div
                className="text-gamma space-y-4"
                dangerouslySetInnerHTML={{
                  __html: body.html,
                }}
              ></div>
            )}
          </div>
          <div className="col-span-12 md:col-span-7">
            <div className="-mr-ogs">
              <StaticImage
                src="../images/tablet.png"
                alt="Introducing Refund Control™"
                placeholder="none"
              />
            </div>
          </div>
        </section>
      </Fade>
    </Element>
  )
}

export default Feature
