import * as React from "react"
import Articles from "./articles/articles"

const Resources = ({ allPrismicResource, isHomePage }) => {
  const { nodes } = allPrismicResource
  return (
    <Articles
      nodes={nodes}
      isHomePage={isHomePage}
      name="Resources"
      slug="resources"
    />
  )
}

export default Resources
