import * as React from "react"
import Fade from "react-reveal/Fade"

const nl2br = require("react-nl2br")

const Panel = ({ heading, body }) => {
  if (!heading) return null

  return (
    <Fade ssrReveal delay={225} duration={500}>
      <section className="relative grid grid-cols-12 gap-gs px-ogs mb-24 md:mb-32 xl:mb-40">
        <div className="relative col-start-1 xl:col-start-2 col-span-12 xl:col-span-10 z-20">
          <div className="md:grid md:grid-cols-12 xl:grid-cols-10 gap-x-gsl bg-blue rounded-md py-8 sm:py-10 md:py-14 xl:py-18 px-6 sm:px-8 md:px-10 xl:px-14">
            {heading && (
              <div className="md:col-span-4 xl:col-span-4 mb-4 md:mb-0">
                <div className="border-l-2 border-white pl-4 sm:pl-6 md:pl-9 py-1">
                  <h3 className="heading-beta max-w-xs text-white">
                    {nl2br(heading)}
                  </h3>
                </div>
              </div>
            )}
            <div className="md:col-span-8 xl:col-span-6 pl-4 sm:pl-6 md:pl-0">
              <div className="max-w-lg">
                {body.html && (
                  <div
                    className="text-gamma text-white space-y-4"
                    dangerouslySetInnerHTML={{
                      __html: body.html,
                    }}
                  ></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fade>
  )
}

export default Panel
