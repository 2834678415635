import * as React from "react"
import Fade from "react-reveal/Fade"
import { Element } from "react-scroll"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const nl2br = require("react-nl2br")

const Item = ({ heading, body, index, image }) => {
  const img = getImage(image.localFile)

  return (
    <Fade ssrReveal delay={225 * (index + 1)} duration={500}>
      <div className="max-w-xs mx-auto text-center">
        {img && (
          <div className="w-32 xl:w-44 mx-auto mb-8 xl:mb-12">
            <GatsbyImage
              image={img}
              alt={image.localFile.alt ?? "Feature Image"}
            />
          </div>
        )}
        {heading && (
          <h3 className="heading-delta mb-4 xl:mb-8">{nl2br(heading)}</h3>
        )}
        {body.html && (
          <div
            className="text-gamma"
            dangerouslySetInnerHTML={{
              __html: body.html,
            }}
          ></div>
        )}
      </div>
    </Fade>
  )
}

const Features = ({ cards }) => {
  if (!cards.length) return null

  return (
    <Element name="lenders" id="lenders">
      <section className="grid grid-cols-12 gap-gs px-ogs section-spacing">
        <div className="xl:col-start-2 col-span-12 xl:col-span-10">
          <div className="grid grid-cols-9 gap-x-gsl gap-y-16">
            {cards.map((item, index) => {
              return (
                <div key={index} className="col-span-9 md:col-span-3">
                  <Item
                    heading={item.section_three_cards_heading}
                    body={item.section_three_cards_body}
                    image={item.section_three_cards_image}
                    index={index}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </Element>
  )
}

export default Features
