import * as React from "react"
import Fade from "react-reveal/Fade"
import { Element } from "react-scroll"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const nl2br = require("react-nl2br")

const IconA = () => {
  return (
    <svg className="w-full h-full" viewBox="0 0 50 50">
      <rect
        width="50"
        height="50"
        rx="5.16"
        className="text-blue fill-current"
      />
      <path
        d="M18.34,18.46H12.7V36.24h5.64Zm-2.78-7.7a2.79,2.79,0,1,0-.09,5.57,2.79,2.79,0,1,0,.09-5.57Zm16.67,7.29a6.69,6.69,0,0,0-5.64,2.87H26.5l-.25-2.46h-5c.09,1.64.17,3.44.17,5.65V36.24h5.64V26a3.75,3.75,0,0,1,.17-1.4,3.16,3.16,0,0,1,2.95-2c2,0,2.86,1.55,2.86,3.85v9.83h5.64V25.75C38.71,20.51,35.93,18.05,32.23,18.05Z"
        className="text-white fill-current"
      />
    </svg>
  )
}

const IconB = () => {
  return (
    <svg className="w-full h-full text-blue fill-current" viewBox="0 0 50 50">
      <polygon points="35.66 10.66 33.48 12.84 44.09 23.46 0 23.46 0 26.54 44.09 26.54 33.48 37.16 35.66 39.34 50 25 35.66 10.66" />
    </svg>
  )
}

const IconC = () => {
  return (
    <svg className="w-full h-full text-blue fill-current" viewBox="0 0 26 26">
      <path d="M13,26A13,13,0,0,1,0,13,13,13,0,1,1,20.69,23.48,12.88,12.88,0,0,1,13,26ZM13,2.08A10.92,10.92,0,1,0,23.92,13,10.93,10.93,0,0,0,13,2.08Zm0,15.61A4.69,4.69,0,1,1,17.69,13,4.69,4.69,0,0,1,13,17.69Zm0-7.3A2.61,2.61,0,1,0,15.61,13,2.61,2.61,0,0,0,13,10.39ZM13,26a12.88,12.88,0,0,1-7.69-2.52L4.8,23.1l.1-.62a8.21,8.21,0,0,1,16.2,0l.1.62-.51.38A12.88,12.88,0,0,1,13,26ZM7.09,22.18a10.91,10.91,0,0,0,11.82,0,6.13,6.13,0,0,0-11.82,0Z" />
    </svg>
  )
}

const Highlight = ({ item }) => {
  return (
    <div className="flex space-x-5 md:space-x-7.5 md:pr-gs">
      <div className="w-5 h-5 flex-shrink-0 flex items-center justify-start mt-1 text-blue">
        <svg className="w-full h-full fill-current" viewBox="0 0 22 22">
          <path d="M7.65,19.66.28,12.26,3.73,8.8l3.92,3.93L18.82,1.52,22.28,5Z" />
        </svg>
      </div>
      {item.highlight && (
        <h5 className="text-gamma">{nl2br(item.highlight)}</h5>
      )}
    </div>
  )
}

const CardWrapper = ({ url, linkWrapper, defaultWrapper, children }) => {
  return url ? linkWrapper(children) : defaultWrapper(children)
}

const Card = ({ index, heading, subHeading, link, thumbnail }) => {
  const image = getImage(thumbnail.localFile)

  return (
    <Fade ssrReveal delay={225 * (index + 1)} duration={500}>
      <div className="h-full">
        <CardWrapper
          url={link.url}
          linkWrapper={children => (
            <a
              className="team-link w-full h-full flex flex-col"
              href={link.url}
              target="_blank"
              rel="noreferrer"
            >
              {children}
            </a>
          )}
          defaultWrapper={children => (
            <div className="w-full h-full flex flex-col">{children}</div>
          )}
        >
          <div className="flex-1 mb-6">
            {image && (
              <div className="team-link__image relative rounded p-4 lg:px-7.5 lg:py-6 mb-5">
                <div className="w-1/3 sm:w-1/2 lg:w-3/4 flex items-center mx-auto">
                  <GatsbyImage
                    image={image}
                    alt={thumbnail.alt ?? "Team Profile Image"}
                    className="rounded-full overflow-hidden transform-gpu"
                  />
                </div>
              </div>
            )}
            {heading && (
              <h4 className="heading-gamma text-blue mb-2.5">
                {nl2br(heading)}
              </h4>
            )}
            {subHeading && (
              <h5 className="text-base leading-tight tracking-tighter text-blue">
                {nl2br(subHeading)}
              </h5>
            )}
          </div>
          <div className="h-2px bg-white mb-2"></div>
          {link.url && (
            <div className="w-full">
              <div className="inline-flex items-center space-x-3 sm:space-x-6.5">
                <span className="block w-5 h-5 sm:w-7.5 sm:h-7.5">
                  <IconA />
                </span>
                <span className="team-link__arrow">
                  <IconB />
                </span>
              </div>
            </div>
          )}
        </CardWrapper>
      </div>
    </Fade>
  )
}

const Item = ({ heading, link }) => {
  return (
    <div className="flex items-center justify-between border-b-2 border-t-2 border-white py-2 sm:py-4 space-x-3 md:space-x-5 -mt-2px">
      {heading && (
        <div className="flex items-center space-x-3 md:space-x-5">
          <span className="w-5 h-5 sm:w-6 sm:h-6 flex-shrink-0">
            <IconC />
          </span>
          <h4 className="heading-eplison text-blue">{nl2br(heading)}</h4>
        </div>
      )}
      {link.url && (
        <a
          href={link.url}
          className="team-link inline-flex items-center space-x-3 sm:space-x-5"
          target="_blank"
          rel="noreferrer"
        >
          <span className="block w-5 h-5 sm:w-7.5 sm:h-7.5">
            <IconA />
          </span>
          <span className="team-link__arrow">
            <IconB />
          </span>
        </a>
      )}
    </div>
  )
}

const Team = ({ prismicTeam }) => {
  const cardParentClass = "col-span-12 sm:col-span-6 md:col-span-4 h-full"

  const { data } = prismicTeam

  return (
    <Element name="team" id="team">
      <section className="grid grid-cols-12 gap-x-gs gap-y-10 lg:gap-y-20 px-ogs">
        <div className="col-span-12 lg:col-span-4">
          <div className="lg:sticky lg:top-header-height-reduced-plus">
            <Fade ssrReveal delay={225} duration={500}>
              <div>
                {data.heading && (
                  <h3 className="heading-alpha max-w-xs lg:max-w-full mb-10 lg:mb-20">
                    {nl2br(data.heading)}
                  </h3>
                )}
                {data.highlights.length > 0 && (
                  <div className="grid grid-cols-2 gap-x-gs gap-y-5 lg:gap-y-7.5 lg:pt-1">
                    {data.highlights.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="col-span-2 md:col-span-1 lg:col-span-2"
                        >
                          <Highlight item={item} />
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            </Fade>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-8">
          {data.heading && (
            <h3 className="hidden lg:block invisible heading-alpha max-w-xs lg:max-w-full mb-10 lg:mb-20 opacity-0">
              {data.heading}
            </h3>
          )}
          {data.founders.length > 0 && (
            <div className="mb-24">
              <Fade ssrReveal delay={225} duration={500}>
                <h4 className="heading-gamma mb-7.5">Founders</h4>
                <div className="h-2px bg-black mb-7.5"></div>
              </Fade>
              <div className="grid grid-cols-12 gap-5.5">
                {data.founders.map((item, index) => {
                  return (
                    <div className={cardParentClass} key={index}>
                      <Card
                        index={index}
                        heading={item.founders_heading}
                        subHeading={item.founders_sub_heading}
                        link={item.founders_link}
                        thumbnail={item.founders_thumbnail}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          )}
          {data.leadership.length > 0 && (
            <div className={`${data.advisory_board.length > 0 ? "mb-24" : ""}`}>
              <Fade ssrReveal delay={225} duration={500}>
                <h4 className="heading-gamma mb-7.5">Leadership</h4>
                <div className="h-2px bg-black mb-7.5"></div>
              </Fade>
              <div className="grid grid-cols-12 gap-5.5">
                {data.leadership.map((item, index) => {
                  return (
                    <div className={cardParentClass} key={index}>
                      <Card
                        index={index}
                        heading={item.leadership_heading}
                        subHeading={item.leadership_sub_heading}
                        link={item.leadership_link}
                        thumbnail={item.leadership_thumbnail}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          )}
          {data.advisory_board.length > 0 && (
            <React.Fragment>
              <h4 className="heading-gamma mb-7.5">Advisory Board</h4>
              {data.advisory_board.map((item, index) => {
                return (
                  <Item
                    key={index}
                    heading={item.advisory_board_heading}
                    link={item.advisory_board_link}
                  />
                )
              })}
            </React.Fragment>
          )}
        </div>
      </section>
    </Element>
  )
}

export default Team
