import * as React from "react"
import Fade from "react-reveal/Fade"
import { StaticImage } from "gatsby-plugin-image"

const nl2br = require("react-nl2br")

const Item = ({ heading, body, index }) => {
  return (
    <Fade ssrReveal delay={225 * (index + 1)} duration={500}>
      <div>
        {heading && (
          <div className="border-l-2 pl-5">
            <h4 className="heading-gamma mb-4 md:mb-7.5">{nl2br(heading)}</h4>
          </div>
        )}
        <div className="border-l-2 border-transparent pl-5 sm:max-w-xs">
          {body.html && (
            <div
              className="text-gamma"
              dangerouslySetInnerHTML={{
                __html: body.html,
              }}
            ></div>
          )}
        </div>
      </div>
    </Fade>
  )
}

const Details = ({ heading, items }) => {
  if (!items.length) return null

  return (
    <Fade ssrReveal delay={225} duration={500}>
      <section className="grid grid-cols-12 gap-x-gs gap-y-10 md:gap-y-14 px-ogs section-spacing">
        <div className="md:hidden col-start-4 col-span-9">
          <Fade ssrReveal delay={225} duration={500}>
            <div className="-mr-ogs">
              <StaticImage
                src="../images/details.png"
                alt="Our lender-controlled workflow works for everyone"
                placeholder="none"
              />
            </div>
          </Fade>
        </div>
        {heading && (
          <div className="col-span-12 md:col-span-5 h-full flex items-center">
            <Fade ssrReveal delay={225} duration={500}>
              <h3 className="heading-alpha max-w-xs md:max-w-full">
                {nl2br(heading)}
              </h3>
            </Fade>
          </div>
        )}
        <div className="hidden md:block col-start-7 col-span-6">
          <Fade ssrReveal delay={225} duration={500}>
            <div className="-mr-ogs">
              <StaticImage
                src="../images/details.png"
                alt="Our lender-controlled workflow works for everyone"
                placeholder="none"
              />
            </div>
          </Fade>
        </div>
        {items.map((item, index) => {
          return (
            <div
              key={index}
              className="col-span-12 sm:col-span-6 lg:col-span-3"
            >
              <Item
                heading={item.section_five_items_heading}
                body={item.section_five_items_body}
                index={index}
              />
            </div>
          )
        })}
      </section>
    </Fade>
  )
}

export default Details
