import * as React from "react"
import Articles from "./articles/articles"

const News = ({ allPrismicPage, isHomePage }) => {
  const { nodes } = allPrismicPage
  return (
    <Articles nodes={nodes} isHomePage={isHomePage} name="News" slug="news" />
  )
}

export default News
