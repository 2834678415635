import React, { useState, useRef } from "react"
import Fade from "react-reveal/Fade"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Pagination } from "swiper"
import "../../node_modules/swiper/swiper-bundle.css"

const nl2br = require("react-nl2br")

const icons = [
  `<svg class="w-full h-full fill-current" viewBox="0 0 146 135">
    <path d="M17.39,74.75,10.63,68.4l2.74-2.92,4,3.77,9.24-8.71,2.74,2.92ZM83,118h33.21a14.5,14.5,0,1,1-.11,4H81a2,2,0,0,1-2-2V69H37V84a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V51a2,2,0,0,1,2-2H35a2,2,0,0,1,2,2V65H79V14a2,2,0,0,1,2-2h35.23a14.49,14.49,0,1,1-.15,4H83V65h33.15a14.54,14.54,0,1,1,0,4H83Zm37-51a10.52,10.52,0,1,0,10.52-10.51A10.53,10.53,0,0,0,120,67Zm0-52.5A10.52,10.52,0,1,0,130.52,4,10.53,10.53,0,0,0,120,14.52ZM33,53H4V82H33Zm87,67.38a10.52,10.52,0,1,0,10.52-10.52A10.53,10.53,0,0,0,120,120.38Z" />
  </svg>`,
  `<svg class="w-full h-full fill-current" viewBox="0 0 122 122">
    <path d="M48.66,55.07H2.37A1.79,1.79,0,0,1,.58,53.29V2.34A1.79,1.79,0,0,1,2.37.56h34a1.76,1.76,0,0,1,1.34.61L50,15.18a1.74,1.74,0,0,1,.44,1.17V53.29A1.78,1.78,0,0,1,48.66,55.07ZM4.15,51.51H46.88V17L35.58,4.12H4.15ZM48.66,18.13H36.39a1.78,1.78,0,0,1-1.78-1.78v-14a1.79,1.79,0,0,1,3.57,0V14.57H48.66a1.78,1.78,0,1,1,0,3.56Zm71.11,36.94H73.47a1.78,1.78,0,0,1-1.78-1.78V2.34A1.78,1.78,0,0,1,73.47.56h34a1.78,1.78,0,0,1,1.34.61l12.27,14a1.79,1.79,0,0,1,.44,1.17V53.29A1.78,1.78,0,0,1,119.77,55.07ZM75.25,51.51H118V17L106.69,4.12H75.25Zm44.52-33.37H107.5a1.79,1.79,0,0,1-1.79-1.79v-14a1.79,1.79,0,0,1,3.57,0V14.57h10.49a1.79,1.79,0,0,1,0,3.57ZM48.66,121.31H2.37a1.79,1.79,0,0,1-1.79-1.79V68.57a1.79,1.79,0,0,1,1.79-1.78h34a1.76,1.76,0,0,1,1.34.61L50,81.41a1.74,1.74,0,0,1,.44,1.17v36.94A1.78,1.78,0,0,1,48.66,121.31ZM4.15,117.74H46.88V83.25L35.58,70.36H4.15ZM48.66,84.37H36.39a1.79,1.79,0,0,1-1.78-1.79v-14a1.79,1.79,0,0,1,3.57,0V80.8H48.66a1.79,1.79,0,1,1,0,3.57Zm71.11,36.94H73.47a1.79,1.79,0,0,1-1.78-1.79V68.57a1.78,1.78,0,0,1,1.78-1.78h34a1.78,1.78,0,0,1,1.34.61l12.27,14a1.79,1.79,0,0,1,.44,1.17v36.94A1.79,1.79,0,0,1,119.77,121.31Zm-44.52-3.57H118V83.25L106.69,70.36H75.25Zm44.52-33.37H107.5a1.79,1.79,0,0,1-1.79-1.79v-14a1.79,1.79,0,0,1,3.57,0V80.8h10.49a1.79,1.79,0,0,1,0,3.57ZM109.38,95.44l-2.76-2.88L93.41,105.23l-6-5.76-2.76,2.89,8.79,8.41Z" />
  </svg>`,
  `<svg class="w-full h-full fill-current" viewBox="0 0 158 97">
    <path d="M109.72,96.2a48.16,48.16,0,0,1-48-46.1H32.08a2,2,0,0,1,0-4H63.63a2,2,0,0,1,2,2A44.1,44.1,0,1,0,109.72,4H2A2,2,0,0,1,2,0H109.72a48.1,48.1,0,0,1,0,96.2ZM129.41,67a2,2,0,0,0-.06-2.83L111.72,47.24V19a2,2,0,1,0-4,0V48.1a2,2,0,0,0,.62,1.44L126.58,67a2,2,0,0,0,1.39.56A2,2,0,0,0,129.41,67Zm-53.3-43a2,2,0,0,0-2-2H18.69a2,2,0,0,0,0,4H74.11A2,2,0,0,0,76.11,23.93Z" />
  </svg>`,
  `<svg class="w-full h-full fill-current" viewBox="0 0 126 156">
    <path d="M57,61.89,45.45,50.37l2.82-2.83L57,56.24l18.54-18.5,2.83,2.83Zm55-12.81A50,50,0,1,0,62,98.94,50,50,0,0,0,112,49.08Zm-4,0A46,46,0,1,1,62,3.22,46,46,0,0,1,108,49.08ZM58,97.27,54.18,96,36.66,147.71,26.21,127.29,5.49,137.23l19.9-58.89L21.6,77.06-1.34,144.94l25.77-12.36,13,25.38Zm43.6,35.31,25.77,12.36L103.85,75.53l-3.79,1.28,20.45,60.42L99.79,127.3,89.28,147.72l-17.58-52L67.91,97l20.61,61Zm-4.38-83.5A35.2,35.2,0,1,0,62,84.2,35.2,35.2,0,0,0,97.19,49.08Zm-4,0A31.2,31.2,0,1,1,62,18,31.2,31.2,0,0,1,93.19,49.08Z" />
  </svg>`,
  `<svg class="w-full h-full fill-current" viewBox="0 0 137 136">
    <path d="M.59,19.57a2,2,0,0,1,2-2h40a2,2,0,0,1,0,4h-40A2,2,0,0,1,.59,19.57Zm74.28,0a2,2,0,0,1,2-2H134a2,2,0,0,1,0,4H76.87A2,2,0,0,1,74.87,19.57Zm-34.29,0A19.15,19.15,0,1,1,59.73,38.71,19.17,19.17,0,0,1,40.58,19.57Zm34.29,0A15.15,15.15,0,1,0,59.73,34.71,15.16,15.16,0,0,0,74.87,19.57ZM.58,68.14a2,2,0,0,1,2-2H71.16a2,2,0,0,1,0,4H2.58A2,2,0,0,1,.58,68.14Zm102.86,0a2,2,0,0,1,2-2H134a2,2,0,0,1,0,4H105.44A2,2,0,0,1,103.44,68.14Zm-34.29,0A19.15,19.15,0,1,1,88.3,87.29,19.17,19.17,0,0,1,69.15,68.14Zm34.29,0A15.14,15.14,0,1,0,88.3,83.28,15.15,15.15,0,0,0,103.44,68.14ZM.58,116.71a2,2,0,0,1,2-2H25.44a2,2,0,1,1,0,4H2.58A2,2,0,0,1,.58,116.71Zm57.15,0a2,2,0,0,1,2-2H134a2,2,0,0,1,0,4H59.73A2,2,0,0,1,57.73,116.71Zm-34.29,0a19.15,19.15,0,1,1,19.14,19.15A19.17,19.17,0,0,1,23.44,116.71Zm34.29,0a15.15,15.15,0,1,0-15.15,15.15A15.16,15.16,0,0,0,57.73,116.71Z" />
  </svg>`,
  `<svg class="w-full h-full fill-current" viewBox="0 0 121 153">
    <path d="M60.5,152.83l-31.71-24.3A73.71,73.71,0,0,1,0,70.17V32.66l14.76-5.58A153,153,0,0,0,48.45,9.18l12-8.63L72.55,9a151.2,151.2,0,0,0,33.69,17.91L121,32.51V70a74.07,74.07,0,0,1-28.79,58.51ZM4,35.42V70.17a69.7,69.7,0,0,0,27.21,55.18L60.5,147.79l29.28-22.44A70.06,70.06,0,0,0,117,70V35.28l-12.17-4.6A154.77,154.77,0,0,1,70.25,12.3L60.51,5.45l-9.75,7A157.28,157.28,0,0,1,16.18,30.82ZM88.28,58.75l-2.83-2.82L51.88,89.7,35.55,73.34l-2.83,2.83,19.16,19.2Z" />
  </svg>`,
  `<svg class="w-full h-full fill-current" viewBox="0 0 147 113">
    <path d="M13,27.55-.47,14.11l2.83-2.83L13,21.9,35.46-.47l2.82,2.83ZM147,10.83H58v4h89ZM38.28,45.52l-2.82-2.83L13,65.12,2.36,54.51-.47,57.34,13,70.78ZM147,54H58v4h89ZM38.28,88.68l-2.82-2.83L13,108.28,2.36,97.67-.47,100.5,13,113.94ZM147,97.22H58v4h89Z" />
  </svg>`,
  `<svg class="w-full h-full fill-current" viewBox="0 0 130 130">
    <path d="M33.73,58.31,19.1,43.43,22,40.63l11.84,12L59.13,27.89l2.79,2.86ZM86.36,42.93A43.43,43.43,0,1,0,42.93,86.36,43.48,43.48,0,0,0,86.36,42.93Zm-4,0A39.43,39.43,0,1,1,42.93,3.5,39.48,39.48,0,0,1,82.36,42.93ZM81.11,78.29l-7.44-7.45-2.83,2.83,7.45,7.44Zm49.52,41.25L83.78,72.69,72.66,83.82l46.85,46.84ZM83.78,78.35,125,119.54,119.51,125,78.31,83.82Z" />
  </svg>`,
  `<svg class="w-full h-full fill-current" viewBox="0 0 134 127">
    <path d="M132.57,35.31,67,3.59,1.43,35.31l-1.74-3.6L67-.85l67.34,32.56ZM121,110H13v4H121Zm11.1,13.73H1.93v4H132.07ZM20.89,34.14h-4v66.15h4Zm48.08,0H65v66.15h4Zm48.14,0h-4v66.15h4Z" />
  </svg>`,
]

const Slider = ({ heading, cards }) => {
  const [showHeading, setShowHeading] = useState(true)

  const nextRef = useRef(null)
  const prevRef = useRef(null)

  SwiperCore.use([Navigation, Pagination])

  const handleOnProgress = (swiper, progress) => {
    if (progress > 0) {
      setShowHeading(false)
    } else {
      setShowHeading(true)
    }
  }

  if (!cards.length) return null

  return (
    <Fade ssrReveal delay={225} duration={500}>
      <section className="lct-slider relative w-screen section-spacing">
        <div className="lct-slider__heading-compact z-10">
          {heading && <h3 className="heading-alpha">{nl2br(heading)}</h3>}
        </div>
        <div className="lct-slider__heading z-10">
          <div
            className={`${
              showHeading ? "opacity-100" : "opacity-0"
            } transition-opacity duration-500`}
          >
            {heading && <h3 className="heading-alpha">{nl2br(heading)}</h3>}
          </div>
        </div>
        <div className="relative z-20">
          <Swiper
            slidesPerView={"auto"}
            navigation={{
              nextEl: nextRef.current,
              prevEl: prevRef.current,
            }}
            onInit={swiper => {
              swiper.params.navigation.prevEl = prevRef.current
              swiper.params.navigation.nextEl = nextRef.current
              swiper.navigation.update()
            }}
            grabCursor
            speed={300}
            onProgress={(swiper, progress) =>
              handleOnProgress(swiper, progress)
            }
          >
            <SwiperSlide className="lct-slide hidden sm:block"></SwiperSlide>
            {cards.map((item, index) => {
              const lastSlideClass =
                index + 1 === cards.length ? "lct-slide--last" : ""

              return (
                <SwiperSlide
                  className={`lct-slide ${lastSlideClass}`}
                  key={index}
                >
                  <div className="lct-slide__inner">
                    <div
                      className="w-18 h-18 sm:w-24 sm:h-24 lg:w-32 lg:h-32"
                      dangerouslySetInnerHTML={{
                        __html: icons[index],
                      }}
                    ></div>
                    {item.section_four_cards_heading && (
                      <div className="h-24 sm:h-28 lg:h-40 flex-shrink-0">
                        <h3 className="heading-beta">
                          <span className="font-medium">
                            {nl2br(item.section_four_cards_heading)}
                          </span>
                        </h3>
                      </div>
                    )}
                  </div>
                </SwiperSlide>
              )
            })}
            <div className="lct-slider__nav pt-4">
              <div className="flex space-x-2">
                <button className="btn-circle btn-circle--alt" ref={prevRef}>
                  <svg className="w-8 h-8 fill-current" viewBox="0 0 50 50">
                    <polygon points="14.34 39.34 16.52 37.16 5.91 26.54 50 26.54 50 23.46 5.91 23.46 16.52 12.84 14.34 10.66 0 25 14.34 39.34" />
                  </svg>
                </button>
                <button className="btn-circle btn-circle--alt" ref={nextRef}>
                  <svg className="w-8 h-8 fill-current" viewBox="0 0 50 50">
                    <polygon points="35.66 10.66 33.48 12.84 44.09 23.46 0 23.46 0 26.54 44.09 26.54 33.48 37.16 35.66 39.34 50 25 35.66 10.66" />
                  </svg>
                </button>
              </div>
            </div>
          </Swiper>
        </div>
      </section>
    </Fade>
  )
}

export default Slider
