import React, { useState, useEffect, useRef } from "react"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Feature from "../components/feature"
import Panel from "../components/panel"
import Features from "../components/features"
import News from "../components/news"
import Resources from "../components/resources"
import Slider from "../components/slider"
import Details from "../components/details"
import Team from "../components/team"
import Contact from "../components/contact"

import { linkResolver } from "../utils/linkResolver"

const IndexPage = ({ data }) => {
  const containerA = useRef(null)
  const containerB = useRef(null)

  const [inZoneA, setInZoneA] = useState(false)
  const [inZoneB, setInZoneB] = useState(false)

  const toggleBodyClass = (a, b) => {
    document.body.classList.add(a)
    document.body.classList.remove(b)
  }

  const eventScroll = () => {
    const zoneA = containerA.current.getBoundingClientRect()
    const zoneB = containerB.current.getBoundingClientRect()
    const target = window.innerHeight / 2

    if (zoneA.top < target && zoneA.bottom > target) {
      if (!inZoneA) {
        setInZoneA(true)
        toggleBodyClass("bg-blue-light", "bg-white")
      }
    } else if (zoneB.top < target && zoneB.bottom > target) {
      if (!inZoneB) {
        setInZoneB(true)
        toggleBodyClass("bg-blue-light", "bg-white")
      }
    } else {
      if (inZoneA || inZoneB) {
        setInZoneA(false)
        setInZoneB(false)
        toggleBodyClass("bg-white", "bg-blue-light")
      }
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", eventScroll, false)
    return () => {
      window.removeEventListener("scroll", eventScroll, false)
    }
  })

  return (
    <Layout isHomePage>
      <Seo
        title={data.prismicHome.data.meta_title}
        description={data.prismicHome.data.meta_description}
      />
      {
        <Hero
          heading={data.prismicHome.data.main_heading}
          subHeading={data.prismicHome.data.main_sub_heading}
          cards={data.prismicHome.data.main_cards}
        />
      }
      <div ref={containerA}>
        <Feature
          heading={data.prismicHome.data.section_one_heading}
          body={data.prismicHome.data.section_one_body}
        />
        <Panel
          heading={data.prismicHome.data.section_two_heading}
          body={data.prismicHome.data.section_two_body}
        />
        <Features cards={data.prismicHome.data.section_three_cards} />
      </div>
      <News allPrismicPage={data.allPrismicPage} isHomePage />
      <Resources allPrismicResource={data.allPrismicResource} isHomePage />
      <Slider
        heading={data.prismicHome.data.section_four_heading}
        cards={data.prismicHome.data.section_four_cards}
      />
      <Details
        heading={data.prismicHome.data.section_five_heading}
        items={data.prismicHome.data.section_five_items}
      />
      <div ref={containerB}>
        <Team prismicTeam={data.prismicTeam} />
      </div>
      <Contact
        heading={data.prismicHome.data.contact_heading}
        body={data.prismicHome.data.contact_body}
      />
    </Layout>
  )
}

export default withPrismicPreview(IndexPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
    linkResolver,
  },
])

export const query = graphql`
  query IndexPageQuery {
    prismicHome {
      _previewable
      data {
        main_heading
        main_sub_heading
        main_cards {
          main_cards_heading
          main_cards_body {
            html
          }
        }
        section_one_heading
        section_one_body {
          html
        }
        section_two_heading
        section_two_body {
          html
        }
        section_three_cards {
          section_three_cards_heading
          section_three_cards_body {
            html
          }
          section_three_cards_image {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        section_four_heading
        section_four_cards {
          section_four_cards_heading
        }
        section_five_heading
        section_five_items {
          section_five_items_heading
          section_five_items_body {
            html
          }
        }
        contact_heading
        contact_body {
          html
        }
        meta_title
        meta_description
      }
    }
    allPrismicPage(limit: 4, sort: { fields: data___date, order: DESC }) {
      nodes {
        uid
        _previewable
        data {
          heading
          excerpt_heading
          excerpt {
            html
          }
          link {
            url
            target
          }
          thumbnail {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    allPrismicResource(limit: 4, sort: { fields: data___date, order: DESC }) {
      nodes {
        uid
        _previewable
        data {
          heading
          excerpt_heading
          excerpt {
            html
          }
          link {
            url
            target
          }
          thumbnail {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    prismicTeam {
      _previewable
      data {
        heading
        highlights {
          highlight
        }
        founders {
          founders_heading
          founders_sub_heading
          founders_link {
            url
          }
          founders_thumbnail {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        leadership {
          leadership_heading
          leadership_sub_heading
          leadership_link {
            url
          }
          leadership_thumbnail {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        advisory_board {
          advisory_board_heading
          advisory_board_link {
            url
          }
        }
      }
    }
  }
`
