import React from "react"
import axios from "axios"
import { withFormik, Form, Field } from "formik"
import { Link } from "gatsby"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const formName = "Contact"

const ContactFormSuccessIcon = () => (
  <svg className="w-full h-full" viewBox="0 0 93 93">
    <path
      d="M46.5 93C20.8217 93 0 72.1783 0 46.5C0 20.8217 20.8217 0 46.5 0C72.1783 0 93 20.8217 93 46.5C93 72.1783 72.1783 93 46.5 93Z"
      fill="#3A72B5"
    />
    <path
      d="M41.2444 64.5572L25.4946 48.7568L32.869 41.3588L41.2444 49.7611L65.1026 25.8262L72.4769 33.2242L41.2444 64.5572Z"
      fill="white"
    />
  </svg>
)

const ContactFormSuccess = () => {
  return (
    <div>
      <div className="absolute -top-4 -right-4 md:-top-8 md:-right-8 xl:-top-10 xl:-right-10 w-10 h-10 md:w-16 md:h-16 xl:w-20 xl:h-20">
        <ContactFormSuccessIcon />
      </div>
      <div className="space-y-6">
        <h3 className="heading-beta">
          Thank you. <br />
          Expect us to reach out soon.
        </h3>
        <div className="h-2px bg-white"></div>
        <p className="text-beta">
          Sign up to receive updates from Lender Compliance Technologies
        </p>
        <Link to="/sign-up" className="btn-primary">
          Sign up
        </Link>
      </div>
    </div>
  )
}

const ContactForm = ({ isSubmitting, errors, values }) => (
  <Form name={formName} data-netlify="true" data-netlify-honeypot="bot-field">
    <input type="hidden" name="form-name" value={formName} />
    <div className="mb-4">
      <label htmlFor="contact-first-name">
        <span className="ContactForm-label">First Name</span>
        <Field
          id="contact-first-name"
          className="ContactForm-field"
          type="text"
          name="first-name"
          required
        />
      </label>
    </div>
    <div className="mb-4">
      <label htmlFor="contact-last-name">
        <span className="ContactForm-label">Last Name</span>
        <Field
          id="contact-last-name"
          className="ContactForm-field"
          type="text"
          name="last-name"
          required
        />
      </label>
    </div>
    <div className="mb-4">
      <label htmlFor="contact-email">
        <span className="ContactForm-label">Email</span>
        <Field
          id="contact-email"
          className="ContactForm-field"
          type="email"
          name="email"
          required
        />
      </label>
    </div>
    <div className="mb-4">
      <label htmlFor="contact-phone">
        <span className="ContactForm-label">Phone</span>
        <Field
          id="contact-phone"
          className="ContactForm-field"
          type="tel"
          name="phone"
          required
        />
      </label>
    </div>
    <div className="mb-4">
      <label htmlFor="contact-company">
        <span className="ContactForm-label">Company</span>
        <Field
          id="contact-company"
          className="ContactForm-field"
          type="text"
          name="company"
          required
        ></Field>
      </label>
    </div>
    <div className="mb-8">
      <label htmlFor="contact-message">
        <span className="ContactForm-label ContactForm-label--not-required">
          Message
        </span>
        <Field
          id="contact-message"
          className="ContactForm-field ContactForm-field--textarea"
          component="textarea"
          name="message"
        ></Field>
      </label>
    </div>
    {errors.submit && (
      <div className="-mt-4 mb-5 bg-orange-alt border border-orange p-2 rounded">
        <p className="font-medium text-sm leading-snug">
          There was an error submitting the form, please try again.
        </p>
      </div>
    )}
    <button
      className="btn-primary"
      type="submit"
      aria-label="Send"
      disabled={isSubmitting}
    >
      Send
    </button>
  </Form>
)

const ContactFormRender = props => {
  const { status } = props
  if (status && status.success) {
    return <ContactFormSuccess />
  } else {
    return <ContactForm {...props} />
  }
}

const ContactFormFormik = withFormik({
  mapPropsToValues: values => ({
    name: values.name || "",
    email: values.email || "",
    phone: values.phone || "",
    message: values.message || "",
  }),
  validate: values => {
    const errors = {}
    return errors
  },
  handleSubmit: async (
    form,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await axios.post(
        "?no-cache=1",
        encode({ "form-name": formName, ...form }),
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      )
      resetForm()
      setStatus({ success: true })
    } catch (error) {
      setErrors({ submit: true })
      if (error.response) {
        console.error(error.response)
      } else {
        console.error(error)
      }
    } finally {
      setSubmitting(false)
    }
  },
})(ContactFormRender)

export default ContactFormFormik
