import * as React from "react"
import Fade from "react-reveal/Fade"
import { Link } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import ArticlesItemInner from "./articlesItemInner"

const ArticlesItem = ({ item, index, slug }) => {
  const { data } = item
  const image = getImage(data.thumbnail.localFile)

  return (
    <Fade ssrReveal duration={250}>
      {data.link.url ? (
        <a
          className="articles-link relative flex flex-wrap items-center border-b border-t -mt-px py-4"
          href={data.link.url}
          target={data.link.target}
          rel="noreferrer"
        >
          <ArticlesItemInner data={data} image={image} />
        </a>
      ) : (
        <Link
          className="articles-link relative flex flex-wrap items-center border-b border-t -mt-px py-4"
          to={`/${slug}/${item.uid}`}
        >
          <ArticlesItemInner data={data} image={image} />
        </Link>
      )}
    </Fade>
  )
}

export default ArticlesItem
