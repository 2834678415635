import * as React from "react"
import Fade from "react-reveal/Fade"
import { StaticImage } from "gatsby-plugin-image"

const nl2br = require("react-nl2br")

const bgColorClasses = ["bg-blue", "bg-green", "bg-orange"]
const bgColorAltClasses = ["bg-blue-alt", "bg-green-alt", "bg-orange-alt"]

const Item = ({ heading, body, index }) => {
  return (
    <Fade ssrReveal delay={225 * (index + 1)} duration={500}>
      <div
        className={`${bgColorAltClasses[index]} h-full rounded-md py-5.5 px-6 relative`}
      >
        <div className="absolute -top-3.5 -left-3.5">
          <div
            className={`${bgColorClasses[index]} w-7.5 h-7.5 flex items-center justify-center text-white rounded-full`}
          >
            <svg className="w-4 h-4 fill-current" viewBox="0 0 22 22">
              <path d="M7.65,19.66.28,12.26,3.73,8.8l3.92,3.93L18.82,1.52,22.28,5Z" />
            </svg>
          </div>
        </div>
        {heading && (
          <h3 className="font-semibold text-delta mb-2">{nl2br(heading)}</h3>
        )}
        {body.html && (
          <div
            className="rich-text-list text-delta"
            dangerouslySetInnerHTML={{
              __html: body.html,
            }}
          ></div>
        )}
      </div>
    </Fade>
  )
}

const Hero = ({ heading, subHeading, cards }) => {
  return (
    <Fade ssrReveal delay={225} duration={500}>
      <section className="pt-8 sm:pt-12 md:pt-18 mb-24 md:mb-32 xl:mb-56">
        <div className="grid grid-cols-12 gap-x-gs gap-y-6 sm:gap-y-8 px-ogs mb-18">
          <div className="col-start-1 md:col-start-1 xl:col-start-2 col-span-12 md:col-span-7 xl:col-span-6 h-full">
            <div className="h-full flex flex-col justify-center">
              {heading && (
                <h1 className="heading-alpha mb-6">{nl2br(heading)}</h1>
              )}
              {subHeading && (
                <h2 className="text-alpha text-gray">{nl2br(subHeading)}</h2>
              )}
            </div>
          </div>
          <div className="row-start-1 md:row-start-auto col-span-12 md:col-span-5 xl:col-span-4">
            <div className="w-52 sm:w-72 md:w-auto mx-auto">
              <StaticImage
                src="../images/hero.png"
                alt="Setting a New Standard for Solving Lender Compliance Issues"
                placeholder="none"
              />
            </div>
          </div>
        </div>
        {cards.length > 0 && (
          <div className="grid grid-cols-12 gap-x-gs px-ogs">
            <div className="col-start-1 col-span-12 xl:col-start-2 xl:col-span-10">
              <div className="grid grid-cols-3 gap-y-ogs gap-x-gsl">
                {cards.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="col-span-3 md:col-span-1 h-full"
                    >
                      <Item
                        heading={item.main_cards_heading}
                        body={item.main_cards_body}
                        index={index}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )}
      </section>
    </Fade>
  )
}

export default Hero
