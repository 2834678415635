import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
const nl2br = require("react-nl2br")

const ArticlesItemInner = ({ image, data }) => {
  return (
    <React.Fragment>
      {image ? (
        <div className="articles-image flex items-center justify-center flex-shrink-0 bg-blue bg-opacity-25 md:mr-9 mb-4 md:mb-0">
          <GatsbyImage
            image={image}
            alt={data.thumbnail.alt ?? "Article Image"}
          />
        </div>
      ) : (
        <div className="articles-image flex items-center justify-center flex-shrink-0 bg-blue bg-opacity-25 md:mr-9 mb-4 md:mb-0">
          <div style={{ paddingTop: "58%" }}></div>
        </div>
      )}
      <div className="flex-1 mr-4 md:mr-9">
        <div className="block max-w-xl articles-link__text">
          {data.excerpt_heading && (
            <h4 className="articles-link-el inline-flex font-bold text-xs uppercase mb-1 md:mb-2">
              {nl2br(data.excerpt_heading)}
            </h4>
          )}
          {data.excerpt.html && (
            <div
              className="text-beta"
              dangerouslySetInnerHTML={{
                __html: data.excerpt.html,
              }}
            />
          )}
        </div>
      </div>
      <div className="block articles-link__arrow">
        <svg className="w-full h-full" viewBox="0 0 50 50">
          <polygon points="35.66 10.66 33.48 12.84 44.09 23.46 0 23.46 0 26.54 44.09 26.54 33.48 37.16 35.66 39.34 50 25 35.66 10.66" />
        </svg>
      </div>
    </React.Fragment>
  )
}

export default ArticlesItemInner
